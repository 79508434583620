import Vue3Toastify, { toast } from 'vue3-toastify'

import 'vue3-toastify/dist/index.css'
import IconCircleChecked from '~/components/UI/icons/IconCircleChecked.vue'
import IconExclamation from '~/components/UI/icons/IconExclamation.vue'
import { IconInfoCircle } from '#components'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(Vue3Toastify, {
        autoClose: 3000,
        hideProgressBar: true
    })

    return {
        provide: {
            toast: {
                success(title: string, text?: string) {
                    let html = '<div class="mt-0.5">'

                    if (title)
                        html += `<div class="text-sm font-medium text-gray-900">${title}</div>`

                    if (text)
                        html += `<p class="text-sm text-gray-500">${text}</p>`
                    html += '</div>'

                    return toast.success(html, {
                        // @ts-ignore
                        icon: IconCircleChecked,
                        dangerouslyHTMLString: true
                    })
                },
                error(title: string, text?: string) {
                    let html = '<div class="mt-px">'

                    if (title)
                        html += `<div class="text-sm font-medium text-gray-900">${title}</div>`

                    if (text)
                        html += `<p class="text-sm text-gray-500">${text}</p>`
                    html += '</div>'

                    return toast.error(html, {
                        // @ts-ignore
                        icon: IconExclamation,
                        dangerouslyHTMLString: true
                    })
                },
                warning(title: string, text?: string) {
                    let html = '<div class="mt-0.5">'

                    if (title)
                        html += `<div class="text-sm font-medium text-gray-900">${title}</div>`

                    if (text)
                        html += `<p class="text-sm text-gray-500">${text}</p>`
                    html += '</div>'

                    return toast.warning(html, {
                        // @ts-ignore
                        icon: IconInfoCircle,
                        dangerouslyHTMLString: true
                    })
                }
            }
        }
    }
})
