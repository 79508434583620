import { defineStore } from 'pinia'
import useSessionStore from '@/store/session'
import { type PasswordResetPayload, type UserPayload } from '~/interfaces/user'

const useAuthStore = defineStore('auth', {
    state: () => ({}),
    actions: {
        async authenticateUser(params: UserPayload) {
            const $app = useNuxtApp()

            const payload = {
                login: params.login,
                password: params.password,
                google_recaptcha_token: null as null | string
            }

            payload.google_recaptcha_token =
                $app.$config.public.CAPTCHA_ENABLED !== 'true'
                    ? null
                    : params.tokenCaptcha

            return $app.$api
                .post('/partners-api/tokens', payload)
                .then((response) => {
                    if (response.status === 200) {
                        const session = useSessionStore()
                        session.setUpUser(response.data)

                        return response.data
                    }
                })
                .catch((e: any) => {
                    throw e
                })
        },
        async refreshUserToken() {
            const { refreshToken } = useSessionStore()
            const { $api, $throwError } = useNuxtApp()

            return $api
                .post('/partners-api/tokens/refresh', {
                    refresh_token: refreshToken
                })
                .then((response) => {
                    if (response.status === 200) {
                        const session = useSessionStore()
                        session.setUpUser(response.data)
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'can not refresh token')
                })
        },
        async sendPasswordReset(email: string) {
            const { $api } = useNuxtApp()

            return $api
                .post('/partners-api/password/reset', {
                    user_email: email
                })
                .catch((e: any) => {
                    throw e
                })
        },
        async updatePasswordReset(paramsPasswordReset: PasswordResetPayload) {
            const { $api, $throwError } = useNuxtApp()

            return $api
                .put(
                    `/partners-api/users/${paramsPasswordReset.userId}/password`,
                    {
                        key: paramsPasswordReset.key,
                        new_password: paramsPasswordReset.password
                    }
                )
                .catch((error: any) => {
                    $throwError(error, 'can not update password')
                })
        },
        async checkPasswordResetKey(key: string) {
            const { $api, $throwError } = useNuxtApp()

            return $api
                .get(`/partners-api/password/key/${key}`)
                .catch((error: any) => {
                    $throwError(error, 'can not check password reset key')
                })
        },
        async logoutUser() {
            const { $api, $throwError } = useNuxtApp()
            const { refreshToken } = useSessionStore()
            const session = useSessionStore()

            return $api
                .delete(`/partners-api/tokens/refresh/${refreshToken}`)
                .then((_: any) => {
                    session.logout()
                })
                .catch((error: any) => {
                    if (error.response?.status === 500) {
                        session.logout()
                    } else {
                        $throwError(error, 'can not logout')
                    }
                })
        },
        async updateUserLang(userId: number, locale: string) {
            const { $api, $throwError } = useNuxtApp()

            return $api
                .patch(`/partners-api/users/${userId}`, {
                    locale: locale.toUpperCase()
                })
                .then((response: any) => {
                    const session = useSessionStore()
                    session.updateUser(response.data)
                })
                .catch((error: any) => {
                    $throwError(error, 'can not update user lang')
                })
        }
    },
    persist: true
})

export default useAuthStore
